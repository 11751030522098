import { AxiosSingular } from '@deleteagency/axios-singular';
import axios from 'axios';
import api from '../../../general/js/api';
import { DcBaseComponent } from '@deleteagency/dc';
import Flickity from 'flickity';
import { deviceObserver } from '@deleteagency/device-observer';
import constants from 'general/js/constants';

export const EVENT_SHOW_MORE_CLICKED = 'eventShowMoreClicked';

export default class SelectedPropertyComponent extends DcBaseComponent {
    constructor(element) {
        super(element);
        this.showAllProperties = false;
        this.isCommercial = this.options.Iscommercial;
    }

    static getNamespace() {
        return 'selected-property';
    }

    onInit() {
        this.TaxonomyNumberOfItemsInt = this.options.OriginalProperties.TaxonomyNumberOfItemsInt;
        this.TaxonomySearchItemsTotalCount = this.options.OriginalProperties.TaxonomySearchItemsTotalCount;
        this.HashCode = this.options.HashCode;
        this.ShowMoreBatchSize = 3;
        this.ShowMoreInt = 0;
        this.moreBtn = this.refs.more;
        this.lessBtn = this.refs.less;
        this.buttonWrapper = this.refs.buttons;


        if (this.moreBtn) {
            this.showMoreOptions = this.options?.OriginalProperties ?? this.options;

            if (this.TaxonomySearchItemsTotalCount <= this.TaxonomyNumberOfItemsInt) {
                this.buttonWrapper.style = "display: none;";
            } else {
                this.assignAttributesToInitialItems();
                this.addListener(this.moreBtn, 'click', () => this.getItems())

                if (this.lessBtn) {
                    this.addListener(this.lessBtn, 'click', () => this.removeItems())
                }
            }
        }

        this._carousel = null;
        this._flickityOptions = {
            contain: true,
            wrapAround: true,
            pageDots: false,
            watchCSS: true,
            prevNextButtons: true,
            arrowShape: constants.CAROUSEL_ARROW_SHAPE,
        };

        if (this.isCommercial) {
            this._removeFlickity();
            return;
        }

        this._initFlickity();
    }

    async getItems() {
        const options = this.showMoreOptions;
        this.axiosSingular = new AxiosSingular(api, axios.CancelToken, axios.isCancel);
        options.TaxonomyNumberOfItemsInt += this.ShowMoreBatchSize;
        options.TaxonomyNumberOfItems = options.TaxonomyNumberOfItemsInt.toString();

        this.ShowMoreInt++;

        return api
            .post('/SelectedPropertiesWidget/ShowMore', {
                data: options,
            })
            .then(
                (response) => {
                    this.options = options;
                    const responseHtml = $(response.request.response);
                    const article = responseHtml.find('article');
                    for (let i = this.TaxonomyNumberOfItemsInt; i < article.length; i++) {
                        const propItem = document.createElement('li');
                        propItem.classList.add('props-panel__prop');
                        propItem.setAttribute('data-prop-showmore-batch', `${this.ShowMoreInt}`);
                        propItem.append(article[i]);
                        this.refs.propList.append(propItem);
                    }

                    this.TaxonomyNumberOfItemsInt = options.TaxonomyNumberOfItemsInt;
                    if (this.TaxonomyNumberOfItemsInt >= this.TaxonomySearchItemsTotalCount) {
                        this.moreBtn.setAttribute('disabled', 'disabled');
                    }

                    if (this.ShowMoreInt > 0) {
                        this.lessBtn.removeAttribute('disabled');
                    }

                    this.showMoreOptions = options;

                },
                (error) => {
                    if (!axios.isCancel(error)) {
                        // eslint-disable-next-line no-console
                        console.error('Actual error!', error);
                    }
                }
            );
    }

    assignAttributesToInitialItems() {
        const lastInitialItem = this.refs.propList.lastElementChild;
        lastInitialItem.setAttribute('data-prop-showmore-batch', 0);
    }

    removeItems() {
        const options = this.showMoreOptions;
        options.TaxonomyNumberOfItemsInt -= this.ShowMoreBatchSize;
        options.TaxonomyNumberOfItems = options.TaxonomyNumberOfItemsInt.toString();

        const lastItems = this.refs.propList.querySelectorAll(`[data-prop-showmore-batch="${this.ShowMoreInt}"]`);
        [...lastItems].forEach(item => item.remove());

        this.ShowMoreInt--;
        this.TaxonomyNumberOfItemsInt = options.TaxonomyNumberOfItemsInt;

        if (this.ShowMoreInt === 0) {
            this.lessBtn.setAttribute('disabled', 'disabled');
        }

        if (this.TaxonomyNumberOfItemsInt < this.TaxonomySearchItemsTotalCount) {
            this.moreBtn.removeAttribute('disabled');
        }

        const newLastItem = this.refs.propList.querySelector(`[data-prop-showmore-batch="${this.ShowMoreInt}"]`);
        if (newLastItem) {
            newLastItem.scrollIntoView();
        }

        this.showMoreOptions = options;
    }

    onDestroy() {
        this._carousel.destroy();
        this._carousel = null;
    }

    _initFlickity() {
        if (deviceObserver.is('>', 'mobile')) {
            this._initDesktopFlickity();
        }

        if (deviceObserver.is('<=', 'mobile')) {
            this._flickityOptions.pageDots = true;
            this._flickityOptions.prevNextButtons = false;
            this._removeNoFlickity();
            this._removeMobileFlickity();
        }
        this._carousel = new Flickity(this.refs.propList, this._flickityOptions);
    }

    _initDesktopFlickity() {
        const childrenCount = this.refs.propList.children.length;
        if ((childrenCount > 3) && !this.isCommercial) {
            this.refs.propList.classList.add('flick-car--desktop-only');
            this._removeNoFlickity();
        }
    }

    _removeMobileFlickity() {
        const childrenCount = this.refs.propList.children.length;
        if (childrenCount < 2){
            this.refs.propList.classList.remove('flick-car--mobile-only');
        }
    }

    _removeNoFlickity() {
        this.refs.propList.classList.remove('props-panel__props--no-flickity');
    }

    _removeFlickity() {
        this._removeNoFlickity();
        this.refs.propList.classList.remove('flick-car--desktop-only');
        this.refs.propList.classList.remove('flick-car--mobile-only');
    }
}
